import axios from 'axios'
import i18n from '@/locales/i18n.js';
import { ElMessage } from 'element-plus'

axios.defaults.timeout = 600000 // 超时时间
// axios.defaults.baseURL = 'http://192.168.2.77:14141/offapi/' //开发
axios.defaults.baseURL = 'https://www.meilinauto.com/offapi/' //正式


// 请求拦截器
axios.interceptors.request.use(async config => {
  config.headers.token = localStorage.getItem('token')
  config.headers.Language = i18n.global.locale.value
  return config
}, error => {
  return Promise.reject(error.response) //请求错误时通过该方法返回配置信息
})

// 响应拦截器
axios.interceptors.response.use(
  response => {
    if (response.data.code == 200 || response.data.code == 10011) {//成功
      return Promise.resolve(response)
    } else if (response.data.code == 10030){//上传文件 上传中
      return Promise.resolve(response)
    } else if (response.data.code == 10006) {//登录过期请重新登录
      localStorage.clear()
      return Promise.resolve(response)
    } else {
      // ElMessage.error(response.data.message)
      return Promise.reject(response); //失败

      // Vue.prototype.$message({
      //   message: response.data.message,
      //   type: "error",
      // })
    }
  },
  error => {
    // console.log(error);
    // if (error.code === 'ECONNABORTED' || error.message === "Network Error" || error.message.includes("timeout")) {
    // }
    return Promise.reject(error.response ? error.response : error.code );
  }
)

// 封装get方法
export const get = (url, params) => {
  return new Promise((resolve, reject) => {
    axios.get(url, { params: params }).then(res => {
      resolve(res.data);
    }).catch(err => {
      reject(err.data)
    })
  });
}

// 封装post请求
export const post = (url, params) => {
  return new Promise((resolve, reject) => {
    axios.post(url,  params ).then(res => {
      resolve(res.data);
    }).catch(err => {
      reject(err.data)
    })
  });
}

export default axios
