import communityRouters from "./community.js"


export const routers = [
  {
    path: '/',
    name: 'index',
    component: () => import("../views/index.vue"),
  },
  {
    path: '/car/autoMall',
    name: 'autoMall',
    component: () => import("../views/car/autoMall.vue"),
  },
  {
    path: '/car/carSearchResult',
    name: 'carSearchResult',
    component: () => import("../views/car/carSearchResult.vue")
  },
  {
    path: '/car/brandIntroduce',
    name: 'brandIntroduce',
    component: () => import("../views/car/brandIntroduce.vue")
  },
  {
    path: '/car/carDetails',
    name: 'carDetails',
    component: () => import("../views/car/carDetails.vue"),
  },
  {
    path: '/car/carDetailsShow',
    name: 'carDetailsShow',
    component: () => import("../views/car/carDetailsShow.vue")
  },
  {
    path: '/car/carDetailsScore',
    name: 'carDetailsScore',
    component: () => import("../views/car/carDetailsScore.vue")
  },
  {
    path: '/car/selectCarOptional',
    name: 'selectCarOptional',
    component: () => import("../views/car/selectCarOptional.vue")
  },
  {
    path: '/car/carConfigurationResults',
    name: 'carConfigurationResults',
    component: () => import("../views/car/carConfigurationResults.vue")
  },
  {
    path: '/car/viewCarConfiguration',
    name: 'viewCarConfiguration',
    component: () => import("../views/car/viewCarConfiguration.vue")
  },

  {
    path: '/shoppingMall/commodityMall',
    name: 'commodityMall',
    component: () => import("../views/shoppingMall/commodityMall.vue")
  },

    //门店
  {
    path: '/store/storeLocator',
    name: 'storeLocator',
    component: () => import("../views/store/storeLocator.vue")
  },
// 经销商
  {
    path: '/store/dealer',
    name: 'dealer',
    component: () => import("../views/store/dealer.vue")
  },

    // 新闻中心
  {
    path: '/store/news',
    name: 'news',
    component: () => import("../views/store/news.vue")
  },
  
  //系统协议
  {
    path: '/system/privacyAgreement',
    name: 'privacyAgreement',
    component: () => import("../views/system/privacyAgreement.vue")
  },

    //用户协议
  {
    path: '/system/userAgreement',
    name: 'userAgreement',
    component: () => import("../views/system/userAgreement.vue")
  },
    // 关于我们
  {
    path: '/system/aboutUs',
    name: 'aboutUs',
    component: () => import("../views/system/aboutUs.vue")
  },
    // 确认订单
  {
    path: '/shoppingMall/confirmOrder',
    name: 'confirmOrder',
    component: () => import("../views/shoppingMall/confirmOrder.vue")
  },
    // 购物车
  {
    path: '/shoppingMall/shoppingTrolley',
    name: 'shoppingTrolley',
    component: () => import("../views/shoppingMall/shoppingTrolley.vue")
  },
    // 商品搜索
  {
    path: '/shoppingMall/shoppingSeach',
    name: 'shoppingSeach',
    component: () => import("../views/shoppingMall/shoppingSeach.vue")
  },
  // 商品详情
  {
    path: '/shoppingMall/shoppingdetails',
    name: 'shoppingdetails',
    component: () => import("../views/shoppingMall/shoppingdetails.vue")
  },
  // 我的收藏
  {
    path: '/user/myCollections',
    name: 'myCollections',
    component: () => import("../views/user/myCollections.vue")
  },
    // 我的订单
  {
    path: '/user/myOrder',
    name: 'myOrder',
    component: () => import("../views/user/myOrder.vue")
  },
    //我的足迹
  {
    path: '/user/myTracks',
    name: 'myTracks',
    component: () => import("../views/user/myTracks.vue")
  },
  // 查看物流
  {
    title: '查看物流',
    path: '/user/logistics',
    name: 'logistics',
    component: () => import("../views/user/logistics.vue")
  },
  // 物流详情
  {
    title: '物流详情',
    path: '/user/LogisticsDetails',
    name: 'LogisticsDetails',
    component: () => import("../views/user/LogisticsDetails.vue")
  },
  // 个人中心
  {
    title: '个人中心',
    path: '/user/personalCenter',
    name: 'personalCenter',
    component: () => import("../views/user/personalCenter.vue")
  },
  //app下载
  {
    path: '/appDownload/appDownload',
    name: 'appDownload',
    component: () => import("../views/appDownload/appDownload.vue")
  },
  ...communityRouters
]
