let vehicleModelCode = [
  //#region 理想

  //#region 理想L6
  {//理想L6 Pro
    title: "理想L6 Pro",
    codeNumber: "5def3ff49d034e3a8c55a4cf11ef6109",//车型版本code
    description1: "LI Auto фокусируется на создании интеллектуальных электромобилей, которые подходят для семейного отдыха, и LI L6 Pro, как важный представитель серии LI L6, поддерживает философию бренда - комфорт, интеллект и практичность - и предоставляет пользователям экономически эффективные решения для передвижения.",
    description2: "Длина, ширина и высота LI L6 Pro составляют 4925/1960/1735 мм соответственно, колесная база - 2920 мм, а общие линии плавные и выразительные. Его простой и атмосферный внешний вид, с закрытой решеткой радиатора спереди и уникальными передними фарами, демонстрирующими ощущение современных технологий.",
    description3: "Оснащенный двухмоторной интеллектуальной системой полного привода, автомобиль разгоняется до 100 километров всего за 5,4 секунды, что делает его мощным транспортным средством. Сиденья в салоне выполнены из кожи Nappa с вентиляцией, подогревом и функцией 10-точечного массажа для передних сидений, что делает поездку комфортной. Оснащен интерактивной системой высокой четкости с четырьмя экранами, включая экран для пассажиров и HUD сверхвысокой четкости, для улучшения впечатлений от вождения. Благодаря использованию литий-железо-фосфатной батареи, запас хода составляет 1 390 км, запас хода на электротяге - 212 км, поддерживается быстрая зарядка."
  },
  {//理想L6 Max
    title: "理想L6 Max",
    codeNumber: "a49bf07baeb84a04b394819bff50e5ba",//车型版本code
    description1: "Компания LI Auto стремится к тому, чтобы обеспечить пользователям первоклассный опыт путешествий. LI L6 Max занимает верхнюю позицию в серии LI L6 и предназначен для семейных пользователей, которые ищут максимальную комплектацию и интеллектуальные возможности.",
    description2: "По внешнему виду LI L6 Max такой же, как L6 Pro, с плавными и вытянутыми линиями кузова, такими же размерами по длине, ширине и высоте, а также колесной базой. Уникальная закрытая решетка радиатора в сочетании с острыми фарами создает полное ощущение технологичности.",
    description3: "LI L6 MAX сопоставим с L6 Pro по мощности и комфорту: тот же интеллектуальный полный привод с двумя двигателями и сиденья из кожи Nappa с вентиляцией, подогревом и массажем. Разница в том, что в L6 Max добавлен небольшой холодильник и более совершенная аудиосистема. Что касается интеллектуального вождения, то в стандартную комплектацию входит система интеллектуального вождения AD Max, добавляющая 1 камеру и 1 LIDAR на базе L6 Pro, оснащенная двумя чипами NVIDIA Orin - X с арифметической мощностью 508TOPS, которые могут поддерживать более сложные функции интеллектуального городского вождения на последующих этапах, а опыт интеллектуального вождения становится еще лучше."
  },
  //#endregion

  //#region 理想L7
  {//理想L7 Pro
    title: "理想L7 Pro",
    codeNumber: "26b3235dae1c45d4acde98cb679fa1cb",//车型版本code
    description1: "LI Auto глубоко предана рынку семейных путешествий. LI L7 Pro, как основная модель LI L7, удовлетворяет потребности семьи в ежедневных поездках и дальних путешествиях, обладая отличными пространственными характеристиками и интеллектуальной конфигурацией, и пользуется большой популярностью у потребителей.",
    description2: "LI L7 Pro имеет широкий кузов, а его общая форма выглядит стильно и атмосферно. Передняя часть имеет уникальный дизайн, группа фар сочетается с решеткой радиатора, демонстрируя неповторимый стиль. Боковые линии кузова плавные, создавая у людей ощущение стабильности. В задней части автомобиля установлены светодиодные фары, которые отличаются простотой и выразительностью, а также обеспечивают высокий уровень видимости в темное время суток.",
    description3: "介绍33"
  },
  {//理想L7 Max
    title: "理想L7 Max",
    codeNumber: "93264309a5b942619099a84b90b95cb4",//车型版本code
    description1: "介绍11",
    description2: "介绍22",
    description3: "介绍33"
  },
  {//理想L7 Air
    title: "理想L7 Air",
    codeNumber: "3b80d4bc3d0444ad9eea5c8d480983d0",//车型版本code
    description1: "介绍11",
    description2: "介绍22",
    description3: "介绍33"
  },
  {//理想L7 Ultra
    title: "理想L7 Ultra",
    codeNumber: "54adce5cfe044d04bdbd5b4f636bdedb",//车型版本code
    description1: "Компания LI делает ставку на семейные поездки, и LI L7 Ultra, как топовая версия линейки LI L7, объединяет в себе передовые технологии бренда и первоклассные комплектации, стремясь обеспечить максимальную роскошь и комфорт в путешествиях для семейных пользователей.",
    description2: "С кузовом более 5 метров и колесной базой 3 метра LI L7 Ultra просторен и надежен. Дизайн кузова стильный и элегантный, уникальные фары и решетка радиатора дополняют друг друга. Боковые линии автомобиля плавные, а багажник на крыше выдерживает нагрузку в 40 кг, что одновременно практично и красиво. В задней части автомобиля установлены габаритные фары, отличающиеся простым и ярким дизайном и высокой узнаваемостью.",
    description3: "LI L7 Ultra оснащен полным приводом с двумя 1,5T двигателями с увеличенным запасом хода и мощностью в 449 л.с. и разгоняется от 0 до 100 км/ч за 5,3 секунды. С системой увеличенного запаса хода чисто электрический режим позволяет преодолевать 180 км в день, а дальний пробег на топливно-электрической синергии превышает 1100 км.  По сравнению с другими версиями, L7 Ultra оснащен 15,7-дюймовым задним развлекательным экраном, обновленной батареей емкостью 52,3 кВт-ч, увеличенным запасом хода на электротяге CLTC до 286 км и 21-дюймовыми колесами,  с более мощными интеллектуальными функциями помощи водителю."
  },
  //#endregion

  //#region 理想L8
  {//理想L8 Pro
    title: "理想L8 Pro",
    codeNumber: "7b7b3e4cf6cc4fa296b21b5581dbab32",//车型版本code
    description1: "介绍11",
    description2: "介绍22",
    description3: "介绍33"
  },
  {//理想L8 Max
    title: "理想L8 Max",
    codeNumber: "684c217019c748eea098fd53ccddf0e7",//车型版本code
    description1: "介绍11",
    description2: "介绍22",
    description3: "介绍33"
  },
  {//理想L8 Air
    title: "理想L8 Air",
    codeNumber: "7d3c744b9467497891c4cf3469728cf7",//车型版本code
    description1: "介绍11",
    description2: "介绍22",
    description3: "介绍33"
  },
  {//理想L8 Ultra
    title: "理想L8 Ultra",
    codeNumber: "1aa02044f9e8417c8b3429ff086c6dda",//车型版本code
    description1: "介绍11",
    description2: "介绍22",
    description3: "介绍33"
  },
  //#endregion

  //#region 理想L9 
  {//理想L9 Ultra
    title: "理想L9 Ultra",
    codeNumber: "32bdc3183c06488eb3130103ae11f736",//车型版本code
    description1: "介绍11",
    description2: "介绍22",
    description3: "介绍33"
  },
  {//理想L9 Pro
    title: "理想L9 Pro",
    codeNumber: "7be1d587e893482e9840e8074d08336c",//车型版本code
    description1: "介绍11",
    description2: "介绍22",
    description3: "介绍33"
  },
  //#endregion

  //#endregion


  //#region 极氪

  //#region 极氪001
  {//极氪001 YOU版
    title: "极氪001 YOU版",
    codeNumber: "9fa63496aa0c4b999e8ad0f266cea387",//车型版本code
    description1: "介绍11",
    description2: "介绍22",
    description3: "介绍33"
  },
  {//极氪001 ME版
    title: "极氪001 ME版",
    codeNumber: "cf7d1529038b4e2583a781b1e70460bf",//车型版本code
    description1: "介绍11",
    description2: "介绍22",
    description3: "介绍33"
  },
  {//极氪001 WE版
    title: "极氪001 WE版",
    codeNumber: "dfc0b0601691462389b0cf8611f3c1d1",//车型版本code
    description1: "介绍11",
    description2: "介绍22",
    description3: "介绍33"
  },
  //#endregion

  //#region 极氪009
  {//极氪009 六座行政版四驱
    title: "极氪009 六座行政版四驱",
    codeNumber: "79adf5dbf6b54c759afc1c633f296187",//车型版本code
    description1: "介绍11",
    description2: "介绍22",
    description3: "介绍33"
  },
  {//极氪009 七座过道版四驱
    title: "极氪009 七座过道版四驱",
    codeNumber: "e76b7c8c99024844ba09c4632bf54b68",//车型版本code
    description1: "介绍11",
    description2: "介绍22",
    description3: "介绍33"
  },
  {//极氪009 七座过道版
    title: "极氪009 七座过道版",
    codeNumber: "16e6c436b80b4eb0aacf28f5c3397200",//车型版本code
    description1: "介绍11",
    description2: "介绍22",
    description3: "介绍33"
  },
  //#endregion

  //#region 极氪X
  {//极氪X 四座魔方版
    title: "极氪X 四座魔方版",
    codeNumber: "0df783b774bd4df18f49bc02248ade83",//车型版本code
    description1: "介绍11",
    description2: "介绍22",
    description3: "介绍33"
  },
  {//极氪X 五座运动版
    title: "极氪X 五座运动版",
    codeNumber: "c9df01123c5d429b9faded0ddfb9f574",//车型版本code
    description1: "介绍11",
    description2: "介绍22",
    description3: "介绍33"
  },
  {//极氪X 五座长续航版
    title: "极氪X 五座长续航版",
    codeNumber: "31fb319106b444a9b65f47eb3e6af7ac",//车型版本code
    description1: "介绍11",
    description2: "介绍22",
    description3: "介绍33"
  },
  {//极氪X 五座后驱版
    title: "极氪X 五座后驱版",
    codeNumber: "bb4210f0e6754cb9b51f206a15f805cc",//车型版本code
    description1: "介绍11",
    description2: "介绍22",
    description3: "介绍33"
  },
  //#endregion

  //#region 极氪007
  {//极氪007 后驱智驾版
    title: "极氪007 后驱智驾版",
    codeNumber: "3babb2ff800c4d3c8bac20f0779b6ddf",//车型版本code
    description1: "介绍11",
    description2: "介绍22",
    description3: "介绍33"
  },
  {//极氪007 长续航后驱智驾版
    title: "极氪007 长续航后驱智驾版",
    codeNumber: "d34c7761284e4368ba1419e91c86ec86",//车型版本code
    description1: "介绍11",
    description2: "介绍22",
    description3: "介绍33"
  },
  {//极氪007 四驱智驾版
    title: "极氪007 四驱智驾版",
    codeNumber: "2697eee126fb4d40ae6950010df9f640",//车型版本code
    description1: "介绍11",
    description2: "介绍22",
    description3: "介绍33"
  },
  {//极氪007 四驱性能版
    title: "极氪007 四驱性能版",
    codeNumber: "4839c6cdaed946f79a6b9ddcfcf196c1",//车型版本code
    description1: "介绍11",
    description2: "介绍22",
    description3: "介绍33"
  },
  //#endregion

  //#region 极氪001FR
  {//极氪001FR
    title: "极氪001FR",
    codeNumber: "09e73a66e1294c74b7d6ecbc5129b771",//车型版本code
    description1: "介绍11",
    description2: "介绍22",
    description3: "介绍33"
  },
  //#endregion

  //#endregion


  //#region 蔚来

  //#region 蔚来ES7
  {//蔚来ES7 签名版
    title: "蔚来ES7 签名版",
    codeNumber: "ddb7b57adfee43588628411863e7314c",//车型版本code
    description1: "介绍11",
    description2: "介绍22",
    description3: "介绍33"
  },
  {//蔚来ES7
    title: "蔚来ES7",
    codeNumber: "13047c878f1b44139f2d176321dba353",//车型版本code
    description1: "介绍11",
    description2: "介绍22",
    description3: "介绍33"
  },
  //#endregion

  //#region 蔚来ES8
  {//蔚来ES8 签名版
    title: "蔚来ES8 签名版",
    codeNumber: "e1d7f62875404a25800a590e947beefa",//车型版本code
    description1: "介绍11",
    description2: "介绍22",
    description3: "介绍33"
  },
  {//蔚来ES8 行政版
    title: "蔚来ES8 行政版",
    codeNumber: "af8d5ac25a5048f1b411ba53c7d3763d",//车型版本code
    description1: "介绍11",
    description2: "介绍22",
    description3: "介绍33"
  },
  {//蔚来ES8
    title: "蔚来ES8",
    codeNumber: "60e1dd2e0f264a6fbaf44fdf22b7946c",//车型版本code
    description1: "介绍11",
    description2: "介绍22",
    description3: "介绍33"
  },
  //#endregion

  //#region 蔚来EC7
  {//蔚来EC7 签名版
    title: "蔚来ES7 签名版",
    codeNumber: "6021d8fbbbf540f2afc30a03fbee0a63",//车型版本code
    description1: "介绍11",
    description2: "介绍22",
    description3: "介绍33"
  },
  {//蔚来EC7
    title: "蔚来ES7",
    codeNumber: "4918c7050cf04353bb91a72761b97ee5",//车型版本code
    description1: "介绍11",
    description2: "介绍22",
    description3: "介绍33"
  },
  //#endregion

  //#region 蔚来ET5
  {//蔚来ET5
    title: "蔚来ET5",
    codeNumber: "cf77cabaff044663b79cbee0681e1f86",//车型版本code
    description1: "介绍11",
    description2: "介绍22",
    description3: "介绍33"
  },
  //#endregion

  //#region 蔚来ET7
  {//蔚来ET7 行政签名版
    title: "蔚来ET7 行政签名版",
    codeNumber: "8984bc6b80fb428b9b36a1dd097d29ad",//车型版本code
    description1: "介绍11",
    description2: "介绍22",
    description3: "介绍33"
  },
  {//蔚来ET7 行政版
    title: "蔚来ET7 行政版",
    codeNumber: "067854723e484fdfb37ae94b75cb55d1",//车型版本code
    description1: "介绍11",
    description2: "介绍22",
    description3: "介绍33"
  },
  //#endregion

  //#region 蔚来ET9
  {//蔚来ET9 签名版
    title: "蔚来ET9 签名版",
    codeNumber: "60b25c2ca41f45b7a3dc432c67e22d8a",//车型版本code
    description1: "介绍11",
    description2: "介绍22",
    description3: "介绍33"
  },
  {//蔚来ET9
    title: "蔚来ET9",
    codeNumber: "ec9cb9ccc7af4abaa4c33f4fb447564c",//车型版本code
    description1: "介绍11",
    description2: "介绍22",
    description3: "介绍33"
  },
  //#endregion

  //#endregion


  //#region 阿维塔

  //#region 阿维塔11
  {//阿维塔11 Ultra 纯电版
    title: "阿维塔11 Ultra 纯电版",
    codeNumber: "d5e81e6f5f174e2c8011559f5dbdb776",//车型版本code
    description1: "介绍11",
    description2: "介绍22",
    description3: "介绍33"
  },
  {//阿维塔11 Ultra 纯电版四驱
    title: "阿维塔11 Ultra 纯电版四驱",
    codeNumber: "10736f21bfe742e994c9a1a5832d57d9",//车型版本code
    description1: "介绍11",
    description2: "介绍22",
    description3: "介绍33"
  },
  {//阿维塔11 皇家剧院版
    title: "阿维塔11 皇家剧院版",
    codeNumber: "c1feac0d57c14a1caddf632b377be7f2",//车型版本code
    description1: "介绍11",
    description2: "介绍22",
    description3: "介绍33"
  },
  {//阿维塔11 Max 增程版
    title: "阿维塔11 Max 增程版",
    codeNumber: "1b2887dcdfd94b20ab83352c3518826d",//车型版本code
    description1: "介绍11",
    description2: "介绍22",
    description3: "介绍33"
  },
  {//阿维塔11 Ultra 增程版
    title: "阿维塔11 Ultra 增程版",
    codeNumber: "ac1ff309d1cc41ee850dfa87984fcdd1",//车型版本code
    description1: "介绍11",
    description2: "介绍22",
    description3: "介绍33"
  },
  //#endregion

  //#region 阿维塔12
  {//阿维塔12 Max纯电版
    title: "阿维塔12 Max纯电版",
    codeNumber: "8c9433503ca24b4b87b25c4558ca0fe9",//车型版本code
    description1: "介绍11",
    description2: "介绍22",
    description3: "介绍33"
  },
  {//阿维塔12 Ultra 纯电版四驱
    title: "阿维塔12 Ultra 纯电版四驱",
    codeNumber: "5573e37e13bb426fa1954f5feed8063a",//车型版本code
    description1: "介绍11",
    description2: "介绍22",
    description3: "介绍33"
  },
  {//阿维塔12 Master 纯电版四驱
    title: "阿维塔12 Master 纯电版四驱",
    codeNumber: "3aa51d7cdc5846fea9b107b6fff729f7",//车型版本code
    description1: "介绍11",
    description2: "介绍22",
    description3: "介绍33"
  },
  {//阿维塔12 皇家剧院版
    title: "阿维塔12 皇家剧院版",
    codeNumber: "207428bb3aa344dda17acff464262d82",//车型版本code
    description1: "介绍11",
    description2: "介绍22",
    description3: "介绍33"
  },
  {//阿维塔12 Max 增程版
    title: "阿维塔12 Max 增程版",
    codeNumber: "6ccc66bfe7f7489bab966bf6eb2348df",//车型版本code
    description1: "介绍11",
    description2: "介绍22",
    description3: "介绍33"
  },
  {//阿维塔12 Ultra 增程版
    title: "阿维塔12 Ultra 增程版",
    codeNumber: "ed3f179418a1412c8208f51fd44328d2",//车型版本code
    description1: "介绍11",
    description2: "介绍22",
    description3: "介绍33"
  },
  //#endregion

  //#endregion


  //#region 岚图

  //#region Free 318
  {//岚图free 318 后驱环游版
    title: "岚图free 318 后驱环游版",
    codeNumber: "f760ac4bbf664927a8873e26f1d4369b",//车型版本code
    description1: "介绍11",
    description2: "介绍22",
    description3: "介绍33"
  },
  {//岚图free 318 四驱探索版
    title: "岚图free 318 四驱探索版",
    codeNumber: "964e13e678714d488c7fba7159050221",//车型版本code
    description1: "介绍11",
    description2: "介绍22",
    description3: "介绍33"
  },
  //#endregion

  //#region 全新岚图梦想家
  {//全新岚图梦想家 四驱卓越鲲鹏版PHEV
    title: "全新岚图梦想家 四驱卓越鲲鹏版PHEV",
    codeNumber: "de7a7838fb384b77bea5ef9fc1579ba8",//车型版本code
    description1: "介绍11",
    description2: "介绍22",
    description3: "介绍33"
  },
  {//全新岚图梦想家 四驱尊贵鲲鹏版PHEV
    title: "全新岚图梦想家 四驱尊贵鲲鹏版PHEV",
    codeNumber: "cb6f5a801c93442a9165b506ec8e8017",//车型版本code
    description1: "介绍11",
    description2: "介绍22",
    description3: "介绍33"
  },
  {//全新岚图梦想家 四驱旗舰鲲鹏版PHEV
    title: "全新岚图梦想家 四驱旗舰鲲鹏版PHEV",
    codeNumber: "86490fa021f74f53a3ff266563038623",//车型版本code
    description1: "介绍11",
    description2: "介绍22",
    description3: "介绍33"
  },
  {//全新岚图梦想家 四驱旗舰乾崑版PHEV
    title: "全新岚图梦想家 四驱旗舰乾崑版PHEV",
    codeNumber: "b955b9ec53214037899a56eefe7150d0",//车型版本code
    description1: "介绍11",
    description2: "介绍22",
    description3: "介绍33"
  },
  {//全新岚图梦想家 四驱卓越鲲鹏版EV
    title: "全新岚图梦想家 四驱卓越鲲鹏版EV",
    codeNumber: "7f27ca39acfa4c62b94a7543409b301c",//车型版本code
    description1: "介绍11",
    description2: "介绍22",
    description3: "介绍33"
  },
  {//全新岚图梦想家 四驱尊贵鲲鹏版EV
    title: "全新岚图梦想家 四驱尊贵鲲鹏版EV",
    codeNumber: "1020c8708cbf464e8830d6501591f75c",//车型版本code
    description1: "介绍11",
    description2: "介绍22",
    description3: "介绍33"
  },
  {//全新岚图梦想家 四驱旗舰鲲鹏版EV
    title: "全新岚图梦想家 四驱旗舰鲲鹏版EV",
    codeNumber: "e1dc9446c805459d802622246b6c9c84",//车型版本code
    description1: "介绍11",
    description2: "介绍22",
    description3: "介绍33"
  },
  {//全新岚图梦想家 四驱旗舰乾崑版EV
    title: "全新岚图梦想家 四驱旗舰乾崑版EV",
    codeNumber: "c8d7311d2858474788d657f381c4da15",//车型版本code
    description1: "介绍11",
    description2: "介绍22",
    description3: "介绍33"
  },
  //#endregion

  //#region 追光
  {//岚图追光超长续航四驱行政版
    title: "岚图追光超长续航四驱行政版",
    codeNumber: "b57d1322669e45e6a913247f4b44dbfb",//车型版本code
    description1: "介绍11",
    description2: "介绍22",
    description3: "介绍33"
  },
  {//岚图追光超长续航四驱旗舰版
    title: "岚图追光超长续航四驱旗舰版",
    codeNumber: "680f64e97ebb4d13a5d3833fc32a49eb",//车型版本code
    description1: "介绍11",
    description2: "介绍22",
    description3: "介绍33"
  },
  {//岚图追光 长续航版
    title: "岚图追光 长续航版",
    codeNumber: "bad7b800ee654dbdacd3f81bd4173727",//车型版本code
    description1: "介绍11",
    description2: "介绍22",
    description3: "介绍33"
  },
  {//岚图追光 标准续航版
    title: "岚图追光 标准续航版",
    codeNumber: "dd7bbddcf7224e9c9332cc535c240373",//车型版本code
    description1: "介绍11",
    description2: "介绍22",
    description3: "介绍33"
  },
  //#endregion

  //#region 岚图梦想家
  {//岚图梦想家 超长续航卓越版PHEV
    title: "岚图梦想家 超长续航卓越版PHEV",
    codeNumber: "cdc5e74186614a8c8a8d1e3bab43fb8b",//车型版本code
    description1: "介绍11",
    description2: "介绍22",
    description3: "介绍33"
  },
  {//岚图梦想家 超长续航尊贵版 PHEV
    title: "岚图梦想家 超长续航尊贵版 PHEV",
    codeNumber: "355ef28c17a04747984659d1a1ad29c6",//车型版本code
    description1: "介绍11",
    description2: "介绍22",
    description3: "介绍33"
  },
  {//岚图梦想家 超长续航旗舰版PHEV
    title: "岚图梦想家 超长续航旗舰版PHEV",
    codeNumber: "568d1f7928644805aa6306809017bae8",//车型版本code
    description1: "介绍11",
    description2: "介绍22",
    description3: "介绍33"
  },
  {//岚图梦想家 超长续航卓越版 EV
    title: "岚图梦想家 超长续航卓越版 EV",
    codeNumber: "2500dbfd30564dbda0a8df404d2b837d",//车型版本code
    description1: "介绍11",
    description2: "介绍22",
    description3: "介绍33"
  },
  {//岚图梦想家 超长续航尊贵版 EV
    title: "岚图梦想家 超长续航尊贵版 EV",
    codeNumber: "3eacc51b2ca5439d9a6edf704fdce4a6",//车型版本code
    description1: "介绍11",
    description2: "介绍22",
    description3: "介绍33"
  },
  {//岚图梦想家 超长续航旗舰版 EV
    title: "岚图梦想家 超长续航旗舰版 EV",
    codeNumber: "4bf9c016cf574d82a6340531740de972",//车型版本code
    description1: "介绍11",
    description2: "介绍22",
    description3: "介绍33"
  },
  {//岚图梦想家 私人订制版 PHEV
    title: "岚图梦想家 私人订制版 PHEV",
    codeNumber: "8c9f596ef00f4c339cd9862f2aa86a24",//车型版本code
    description1: "介绍11",
    description2: "介绍22",
    description3: "介绍33"
  },
  //#endregion

  //#endregion


  //#region 小米

  //#region 小米SU7
  {//小米SU7
    title: "小米SU7",
    codeNumber: "898f42ed3bfe47369652cbd378d1e68d",//车型版本code
    description1: "介绍11",
    description2: "介绍22",
    description3: "介绍33"
  },
  {//小米SU7 Pro
    title: "小米SU7 Pro",
    codeNumber: "0adf26a70f764e65805528f7140599e8",//车型版本code
    description1: "介绍11",
    description2: "介绍22",
    description3: "介绍33"
  },
  {//小米SU7 Max
    title: "小米SU7 Max",
    codeNumber: "154bbb2ac800404284c4dcd68698abec",//车型版本code
    description1: "介绍11",
    description2: "介绍22",
    description3: "介绍33"
  },
  //#endregion

  //#endregion


  //#region 路特斯

  //#region ELETRE
  {//路特斯 ELETRE 600旅行版
    title: "路特斯 ELETRE 600旅行版",
    codeNumber: "8df6adcfc59d49c9ae170576570ce127",//车型版本code
    description1: "介绍11",
    description2: "介绍22",
    description3: "介绍33"
  },
  {//路特斯 ELETRE 600豪华版
    title: "路特斯 ELETRE 600豪华版",
    codeNumber: "3705c3330c4e411597d3e76935dcf569",//车型版本code
    description1: "介绍11",
    description2: "介绍22",
    description3: "介绍33"
  },
  {//路特斯 ELETRE 600行政版
    title: "路特斯 ELETRE 600行政版",
    codeNumber: "a63f8091cc2f40dd8aa4437250892545",//车型版本code
    description1: "介绍11",
    description2: "介绍22",
    description3: "介绍33"
  },
  //#endregion

  //#region EMEYA
  {//路特斯EMEYA 600旅行版
    title: "路特斯EMEYA 600旅行版",
    codeNumber: "94c4baf333a84743917eaba9042a66fb",//车型版本code
    description1: "介绍11",
    description2: "介绍22",
    description3: "介绍33"
  },
  {//路特斯EMEYA 600豪华版
    title: "路特斯EMEYA 600豪华版",
    codeNumber: "380ba43d36f94e25b7a37ce2c597e2d3",//车型版本code
    description1: "介绍11",
    description2: "介绍22",
    description3: "介绍33"
  },
  {//路特斯EMEYA 600行政版
    title: "路特斯EMEYA 600行政版",
    codeNumber: "e823f52508f6475f8783bdc1bc9ba7d6",//车型版本code
    description1: "介绍11",
    description2: "介绍22",
    description3: "介绍33"
  },
  {//路特斯EMEYA 900性能版
    title: "路特斯EMEYA 900性能版",
    codeNumber: "f8fb36e6251a4a49a9d163769fe0e7fd",//车型版本code
    description1: "介绍11",
    description2: "介绍22",
    description3: "介绍33"
  },
  {//路特斯EMEYA 900行政版
    title: "路特斯EMEYA 900行政版",
    codeNumber: "de47eb29002e44f8ac9cb49c0ff0d002",//车型版本code
    description1: "介绍11",
    description2: "介绍22",
    description3: "介绍33"
  },
  //#endregion

  //#endregion


  //#region 问界

  //#region 新M5 Ultra
  {
    title: "问界 新M5 增程 Ultra 后驱版",
    codeNumber: "ca8fa989533c400a9ae7c64fa590257c",
    description1: "介绍11",
    description2: "介绍22",
    description3: "介绍33"
  },
  {
    title: "问界 新M5 增程 Ultra 四驱版",
    codeNumber: "5dfb6a0e9c5a481eac5c66f12546e6f4",
    description1: "介绍11",
    description2: "介绍22",
    description3: "介绍33"
  },
  {
    title: "问界 新M5 纯电 Ultra 后驱版",
    codeNumber: "e126a043edd045ca834b8d6bf4e04a09",
    description1: "介绍11",
    description2: "介绍22",
    description3: "介绍33"
  },
  //#endregion

  //#region M7
  { 
    title: "问界 M7 旗舰版",
    codeNumber: "633870f63c5246bcb397f2a7ece8d1df",
    description1: "介绍11",
    description2: "介绍22",
    description3: "介绍33"
  }, 
  { 
    title: "问界 M7 舒适版",
    codeNumber: "33b303b2f40f4b9a9e5d4feb7556391a",
    description1: "介绍11",
    description2: "介绍22",
    description3: "介绍33"
  },
  { 
    title: "问界 M7 豪华版",
    codeNumber: "c6c60319969444caa4afa67dc1700358",
    description1: "介绍11",
    description2: "介绍22",
    description3: "介绍33"
  },
  //#endregion

  //#region M5（2023款）
  {
    title:"问界M5 后驱标准版（2023款）",
    codeNumber:"e225839558494adb8b85266726078a1e",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  {
    title:"问界 M5 四驱标准版（2023款）",
    codeNumber:"129cb76c1cd14a4e8d1a70db3264e6a4",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  {
    title:"问界 M5 纯电后驱标准版（2023款）",
    codeNumber:"fe93837f326342f982c0a33cfe80d772",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  //#endregion

  //#region 新M7
  {
    title:"问界 新M7 Pro 五座后驱智驾版",
    codeNumber:"506785c481c34b239733ed43c8bcb794",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  {
    title:"问界 新M7 Pro 五座四驱智驾版",
    codeNumber:"6d97ac61e560441398a90ca034ccbc80",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  {
    title:"问界 新M7 Pro 六座后驱智驾版",
    codeNumber:"05485c5ce29947219e7a745b4c3f4f2d",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  {
    title:"问界 新M7 Pro 六座四驱智驾版",
    codeNumber:"74d5f6fd9a9647e6a785aa1a313c254c",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  //#endregion

  //#region 新M7 大五座
  {
    title:"问界 新M7 Plus 后驱版",
    codeNumber:"bc6f3c5063c84d6db9a2668678a8c558",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  {
    title:"问界 新M7 Plus 四驱版",
    codeNumber:"71074801a97241d7a8eb6eab22f1fceb",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  {
    title:"问界 新M7 Plus 后驱版",
    codeNumber:"e41a13c4fc2c498d8eef14b47a3948ba",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  //#endregion

  //#region M9
  {
    title:"问界M9 增程 Max 六座版（42度电池）",
    codeNumber:"aa3690bc51a94d6da3fc4bb12d6a2826",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  {
    title:"问界 M9 增程 Ultra 五座版（42度电池）",
    codeNumber:"85fc7e5d79cd4119bc8bac6f50cd6f8b",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  {
    title:"问界 M9 纯电 Max 六座版（100度电池）",
    codeNumber:"227c4937ea724c02ace47a4a4ea4550d",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  {
    title:"问界M9 纯电Ultra 五座版（100度电池）",
    codeNumber:"c274c5e255c7476ea2ba03135b867e2f",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  //#endregion

  //#region 新 M5
  {
    title:"问界 新M5 增程 Max",
    codeNumber:"18b3b1cd747f4646b8260191f99f4b88",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  {
    title:"问界 新M5 增程 Max RS",
    codeNumber:"74bb49b9c936450c9af4d7207801a60f",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  {
    title:"问界 新M5 纯电 Max",
    codeNumber:"3dd511483d1b41c281969eb0823b5956",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  {
    title:"问界M5 四驱旗舰版",
    codeNumber:"48c7758264f64ecc862f7dafaa4b2f8a",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  //#endregion

  //#endregion


  //#region 仰望

  //#region U8
  {
    title:"仰望U8 豪华版",
    codeNumber:"4b9a30b01c814db48074df8d883c5b40",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  {
    title:"仰望U8 越野玩家版",
    codeNumber:"9672bf589faf45a198561b36dcc66cb5",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  //#endregion

  //#region U9
  {
    title:"仰望 U9",
    codeNumber:"470e71f9a5e7432a8baa56b19f37e183",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  //#endregion

  //#endregion


  //#region 领克

  //#region 08 EM-P
  {
    title:"领克08 EM-P 220四驱性能Halo",
    codeNumber:"63dd8106825c4f96b2bdd9c7b51289a3",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  {
    title:"领克 08 EM-P 245超长续航Halo",
    codeNumber:"43e0f8760d734065960b41b3e88f4915",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  {
    title:"领克08 EM-P 245超长续航Pro",
    codeNumber:"424921511d864037b672ce061db3a38a",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  {
    title:"领克 08 EM-P 120长续航Halo",
    codeNumber:"9a1ad4ac69e54590a72762e3d27124a6",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  {
    title:"领克 08 EM-P 120长续航Pro",
    codeNumber:"d11b1698d73549f79c7d2ab8eabac657",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  {
    title:"领克08 EM-P120 长续航Plus",
    codeNumber:"a06eb9b063a949618fff0a04051c9243",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  //#endregion

  //#region Z10
  {
    title:"领克z10 71kWh 602km后驱Pro",
    codeNumber:"3077bd0805644c4c8027c2c138879228",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  {
    title:"领克z10 95kWh 766km 后驱Max",
    codeNumber:"95c799bbc6894f54994cbd1dcfabe5fb",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  {
    title:"领克z10 95kWh 806km 后驱智驾版Max",
    codeNumber:"d52fd0c078954ebc9b3ed894f8a27133",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  {
    title:"领克z10 95kWh 702km四驱Halo",
    codeNumber:"537bc8c27d9b4f23904bf95e00f46f92",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  {
    title:"领克z10 95kWh 702km四驱智驾Ultra",
    codeNumber:"2c4f34cc3e3042f1b9a62e77392b2783",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  //#endregion

  //#endregion


  //#region 腾势

  //#region D9 DM-i
  {
    title:"腾势D9 DM-i970豪华型 24款",
    codeNumber:"19892e13c645468f84ae629e4428b468",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  {
    title:"腾势D9 DM-i1050尊享型 24款",
    codeNumber:"ffe7d07c222b41c2b2f7fb17e7fdf101",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  {
    title:"腾势D9 DM-i 980四驱尊贵型 24款",
    codeNumber:"d9af82626d7e4981b8adc48bd5b55762",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  {
    title:"腾势D9 DM-i980四驱尊荣型 24款",
    codeNumber:"12a2979ba19742bd827c7cc23b0653f2",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  {
    title:"腾势D9 DM-i980四驱旗舰型 24款",
    codeNumber:"ad6e61ddfbc74ba493df8b82e7accdde",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  //#endregion

  //#region D9 EV
  {
    title:"腾势D9 EV620 尊享型 24款",
    codeNumber:"898dba1adab24390a658f367257609e2",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  {
    title:"腾势D9-EV600 四驱尊贵型 24款",
    codeNumber:"c8d44b7bbf9c4dd3a4453696b6e5b767",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  {
    title:"腾势D9 EV600四驱旗舰型 24款",
    codeNumber:"099f8bca3776480d9f1272d794c1c190",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  //#endregion

  //#endregion


  //#region 小鹏

  //#region X9
  {
    title:"小鹏X9 610长续航Pro",
    codeNumber:"a5ea51eaf7124fd2aeaa704f376751e5",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  {
    title:"小鹏X9 610长续航 Max",
    codeNumber:"16fc5ca652d74afa82a80d7abd7db58e",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  {
    title:"小鹏X9 702 超长续航Pro",
    codeNumber:"1f6b415fdfb948d0abd41f3a05ca3bab",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  {
    title:"小鹏X9 702超长续航 Max",
    codeNumber:"90853689434748cf97ae8c1a1587c6f0",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  {
    title:"小鹏X9 640四驱高性能 Max",
    codeNumber:"46e1cdaaff6049979922cd410076dd47",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  //#endregion

  //#endregion


  //#region 坦克

  //#region 400 Hi4T
  {
    title:"2024款坦克400 Hi4-T越野版",
    codeNumber:"d67b0e6c0e9c48b89abee3b0ed2a5d6c",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  {
    title:"2024款坦克400 Hi4-T城市版",
    codeNumber:"ff35fdcc3edf4e9381e98cd1c2489adc",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  //#endregion

  //#endregion


  //#region 猛士

  //#region 917
  {
    title:"猛士917 纯电版",
    codeNumber:"81b0839fdb13424c8128617dc9ab4e00",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  {
    title:"猛士917 增程版",
    codeNumber:"7930192d787d4400bff5adc4b14e34cc",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  //#endregion

  //#endregion


  //#region 比亚迪

  //#region 唐EV
  {
    title:"比亚迪 唐EV 635KM 四驱旗舰型（六座）",
    codeNumber:"23e650c77c1149fda26761dab4b60186",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  {
    title:"比亚迪 唐EV 730KM尊享型（六座臻享组合套装）",
    codeNumber:"363dc22a7b7b4aa18bd08467c24a7509",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  {
    title:"比亚迪 唐EV 730KM尊享型（7座）",
    codeNumber:"89576d97054445f4b12ba65ce8062c40",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  {
    title:"比亚迪 唐EV 600KM尊享型（六座臻享组合套装）",
    codeNumber:"32df52330ae8438c8b2221680a5ac749",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  {
    title:"比亚迪 唐EV 600KM尊享版（7座）",
    codeNumber:"6ee0f5c98648490eb0aa2d9ccdb0b7c4",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  //#endregion

  //#endregion


  //#region 红旗

  //#region H9
  {
    title:"红旗H9 2022款2.0T 智联 旗悦版",
    codeNumber:"a9d0f5fb9ab24a3491b0a2268b150b4c",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  {
    title:"红旗H9 2023款 2.0T 智联 旗享版",
    codeNumber:"107d3dd7063d49438f802dcdf7a33e14",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  {
    title:"红旗H9 2023款2.0T 智联 旗畅版",
    codeNumber:"a9e0ce99912e4ce89cad4744ff70e1ea",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  {
    title:"红旗H9 2022款3.0T智联旗领四座版",
    codeNumber:"fbeb02e423c645b6ab71febdb30af224",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  {
    title:"红旗H9 2022款3.0T智联 旗畅版",
    codeNumber:"79b10974ae0a41a59e39e541e6ac8c4d",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  //#endregion

  //#region E-HS9
  {
    title:"红旗E-HS9 2025款 120kWh旗享六座版",
    codeNumber:"99c09bd1bc8c4eb996329586475d075d",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  {
    title:"红旗E-HS9 2025款 120kWh旗悦七座版",
    codeNumber:"364d2782d5954c36a2e82fc6f148913b",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  {
    title:"红旗E-HS9 2025款 120kWh 旗畅六座版",
    codeNumber:"2ebe7e3d499d4190a02dff2cbc60c844",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  {
    title:"红旗E-HS9 2025款 120kWh旗领四座版",
    codeNumber:"e1642b04169b4c518722b7a692eafd71",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  {
    title:"红旗E-HS9 2022款 改款 510km 旗领版四座",
    codeNumber:"00fa604f7a8449ca89621dc36cb06e04",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  {
    title:"红旗E-HS92022款改款 510km 旗畅版六座",
    codeNumber:"76ca6646660646ea9db1e5f74743e485",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  {
    title:"红旗E-HS92022款改款 460km 旗享版六座",
    codeNumber:"ad06a4323e9b4efcaf3fbfab584850ff",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  //#endregion

  //#region H5
  {
    title:"红旗H5 2024款 1.5T HEV智联旗韵版",
    codeNumber:"26473f874a6a424787ac1e69ccd6ac88",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  {
    title:"红旗H5",
    codeNumber:"7af89a76699c46d8b7de9cb439140d8d",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  {
    title:"红旗H52024款1.5T HEV 智联旗畅版",
    codeNumber:"16f4fca8d30549c1ba9c750dafba36fa",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  {
    title:"红旗H52023款1.5T HEV 智联旗畅版",
    codeNumber:"4755ae160cc84727a95f611c5de1fb62",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  {
    title:"红旗H52023款1.5T HEV 智联旗领版",
    codeNumber:"4852fd02a72547c9ac2616f99e8a5fa2",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  //#endregion

  //#region EH7
  {
    title:"红旗EH7 600Pro",
    codeNumber:"7ad6565375d24f94828bc09cb8fc3aad",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  {
    title:"红旗EH7 640 Pro+四驱版",
    codeNumber:"fd4d198e0c9643d08bb3ffcc4de50d5f",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  {
    title:"红旗EH7 690 Pro",
    codeNumber:"bc3e87ffcb474221b56687c0c2b24aa9",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  {
    title:"红旗EH7 760 Pro+四驱版",
    codeNumber:"011bcc27d143476888a1918c597107f5",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  {
    title:"红旗EH7 820 Pro",
    codeNumber:"fe348f8d7ecc46f7b02a220c52c5162f",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  //#endregion

  //#endregion


  //#region 智己

  //#region LS6
  {
    title:"智己 LS6 超强性能灵蜥智享版",
    codeNumber:"cff3625fea3a4e98b7680584d4989953",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  {
    title:"智己 LS6 超长续航灵蜥智驾版",
    codeNumber:"0853d442f71f4466905e192db9fd1aec",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  {
    title:"智己 LS6 长续航灵蜥智享版",
    codeNumber:"6e2d74631b1045629bc66532014d8db9",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  {
    title:"智己 LS6 长续航灵蜥智驾版",
    codeNumber:"eafd658e6d554320b86dfe3f3ddcfe6a",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  //#endregion

  //#endregion


  //#region 合创

  //#region V09
  {
    title:"合创V09 豪华版",
    codeNumber:"05946c642c694fda9d5dd3893eb3b292",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  {
    title:"合创V09 尊荣版",
    codeNumber:"0ef85b09aff44af3b5b28a3b7d79eda0",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  {
    title:"合创V09 旗舰版",
    codeNumber:"c3d45509abe34bea86ee635dc7ba2473",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  //#endregion

  //#endregion


  //#region 魏牌

  //#region 高山
  {
    title:"魏牌高山 四驱尊贵版",
    codeNumber:"d6d8e87d94034b8dae86d8cc29282a17",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  {
    title:"魏牌高山 四驱旗舰版",
    codeNumber:"b897d695194d417c9720fba5b22d8ed8",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  //#endregion

  //#endregion


  //#region 极越

  //#region 01
  {
    title:"极越01 Max",
    codeNumber:"7878db4a0e5348baaf6842a6cb80e319",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  {
    title:"极越01 Max 长续航版",
    codeNumber:"1765b5d5628549d4a499e9720fa70732",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  {
    title:"极越01 Max性能版",
    codeNumber:"9a7c0063573d43e79819120d5080d5e7",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  //#endregion

  //#endregion


  //#region 方程豹

  //#region 豹5
  {
    title:"方程豹 豹5 探索版",
    codeNumber:"b569916362604dcca08af873b7e70126",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  {
    title:"方程豹 豹5 领航版",
    codeNumber:"ef90ddff7be54b73a5c84407c2097522",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  {
    title:"方程豹 豹5 云辇豪华版",
    codeNumber:"d13cdf36ebb94327bef7485b5fabafbd",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  {
    title:"方程豹 豹5 云辇旗舰版",
    codeNumber:"1ef514ea85e74125a258a48f51e6a5d3",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  //#endregion

  //#endregion


  //#region 极石

  //#region 01
  {
    title:"极石01 长续航7座版",
    codeNumber:"4a19a51d88fe474286e3141e72206082",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  {
    title:"极石 01 长续航 6座版",
    codeNumber:"b1abab7dd7664cc580fc76ae0d3d949b",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  {
    title:"极石 01 标准续航 7座版",
    codeNumber:"1ab5dfa9dc9642b0a97e22799da41ada",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  {
    title:"极石 01 标准续航 6座版",
    codeNumber:"6a85ab28869a4d86b68a2d96c20f21df",
    description1:"介绍11",
    description2:"介绍22",
    description3:"介绍33"
  },
  //#endregion

  //#endregion
  
]

export function selectSEOIntroduction(code) {
  return vehicleModelCode.find(item => item.codeNumber === code)
}