import Vue, { createApp } from 'vue'
import App from './App.vue'
// import { TUIComponents, TUIChatKit, genTestUserSig } from "./TUIKit"; 找不到路径原因不明
import "@/permission" // 权限
import '@/style/base.scss' // 引入全局公用样式

// 国际化
import i18n from "./locales/i18n.js"
const { t } = i18n.global;

// 路由
import router from './router/index.js'
// 全局路由守卫 根据codeNumber动态设置meta标签
import { selectSEOIntroduction } from './router/SEOVehicleModelCode.js'
router.beforeEach((to, from, next) => {
  let text = "";
  if(to.name === 'index'){//首页
    text = "首页介绍";
  } else if(to.path === '/car/autoMall'){//车辆商城
    text = "车辆商城";
  } else if(to.path === '/shoppingMall/commodityMall'){//配件商城
    text = "配件商城";
  } else if(to.path === '/community/index'){//meilin社区
    text = "meilin社区";
  } else if(to.path === '/store/news'){//新闻中心
    text = "新闻中心";
  } else if(to.path === '/car/carDetails' && to.query.codeNumber){//车型介绍页面
    let seo = selectSEOIntroduction(to.query.codeNumber);
    if(seo){
      text = seo.description1
    }
  } else if(to.path === '/car/viewCarConfiguration' && to.query.codeNumber){//车型配置页面
    let seo = selectSEOIntroduction(to.query.codeNumber);
    if(seo){
      text = seo.description2
    }
  } else if(to.path === '/car/selectCarOptional' && to.query.carVersionCode){//车型配置页面
    let seo = selectSEOIntroduction(to.query.carVersionCode);
    if(seo){
      text = seo.description3
    }
  } else {//其他页面清空meta标签
    text = ""
  }

  let descriptionTag = document.querySelector('meta[name="description"]')
  if (!descriptionTag) {
    descriptionTag = document.createElement('meta')
    descriptionTag.setAttribute('name', 'description')
    document.head.appendChild(descriptionTag)
  }
  descriptionTag.setAttribute('content', text)

  next()
})

// element-plus
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
const app = createApp(App)

app.config.globalProperties.$t = t; // i18l方法 注册为全局方法

import * as ELIcons from '@element-plus/icons-vue'
for (let iconName in ELIcons) {
  app.component(iconName, ELIcons[iconName])
}

import * as globalComponents from './components/registrationComponent.js';
Object.keys(globalComponents).forEach(key => {
  const component = globalComponents[key];
  app.component(key, component);
});
import myUI from './components'

// 引入IM
import TIM from '@tencentcloud/chat';
import TIMUploadPlugin from 'tim-upload-plugin';
let options = {
  SDKAppID: 1600001774//1600001774 1600009253
};
let chat = TIM.create(options); 
chat.setLogLevel(4); 
chat.registerPlugin({'tim-upload-plugin': TIMUploadPlugin});
app.config.globalProperties.$chat = chat; // 挂载
app.config.globalProperties.$TIM = TIM; // 挂载

app.use(router); //注册路由
app.use(i18n); //注册国际化
app.use(ElementPlus) //注册element-plus
app.use(myUI)
app.mount('#app');
