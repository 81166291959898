import { routers } from './routers.js'
import { createRouter,createWebHashHistory,createWebHistory  } from "vue-router";

const router = createRouter({
    history: createWebHashHistory(),
    routes: routers,
    // 每次切换路由后滚动条置顶
    scrollBehavior(to, from, savedPosition) {
        return { left: 0, top: 0 }
    }
})

export default router